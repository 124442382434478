import React, {useEffect} from 'react';
import Text from '../Text';
import styled from 'styled-components/native';
import {View, Image, TouchableOpacity} from 'react-native';
import LayerSelectionIcon from '../../assets/images/layer-selection-icon.png';
import CancelIcon from '../../assets/images/cancel-icon.png';
import colors from '../../constants/colors';
import {useGetLayersQuery, LayerType} from '../../graphql-types';
import {useLoading} from '../../hooks/useLoadingContext';
import {useLayerSelection} from '../../hooks/useLayerSelectionContext';

const Container = styled(View)`
  background-color: #fff;
  elevation: 5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  flex-grow: 1;
`;

const Header = styled(View)`
  display: flex;
  flex-direction: row;
  padding: 5px;
`;

const HeaderIcon = styled(Image)`
  height: 24px;
  width: 24px;
`;

const HeaderTitle = styled(Text)`
  flex-grow: 1;
  padding: 5px;
  color: ${colors.mote.primary.blue};
  font-weight: bold;
`;

const CloseButton = styled(TouchableOpacity)``;
const CloseIcon = styled(Image)`
  height: 18px;
  width: 18px;
`;

const Current = styled(View)`
  background-color: ${colors.mote.secondary.manateeGray}
  padding: 10px;
  display: flex;
  flex-direction: row;
`;
const CurrentLabel = styled(Text)`
  font-weight: bold;
  padding-right: 15px;
`;
const CurrentLayer = styled(Text)`
  flex-grow: 1;
`;

const Layers = styled(View)`
  padding-top: 5px;
  padding-left: 10px;
`;

const Layer = styled(TouchableOpacity)`
  padding: 10px;
`;
const LayerTitle = styled(Text)`
  font-size: 18px;
`;
const LayerDescription = styled(Text)``;

type SelectLayerProps = {
  onClose: () => void;
};

export default function SelectLayer({onClose}: SelectLayerProps) {
  const {set: setLayerSelection, layer: current} = useLayerSelection();
  const {data, loading} = useGetLayersQuery({
    variables: {
      type: LayerType.Csic,
    },
  });

  const selectLayer = (layerId: string) => {
    setLayerSelection(layerId);
    onClose();
  };

  if (!data || !data.layers) {
    // @todo error check this
    return null;
  }

  const selectedLayer = data.layers.find(layer => layer.id === current);
  const layers = data.layers.filter(layer => layer.id !== current);

  return (
    <Container>
      <Header>
        <HeaderIcon source={LayerSelectionIcon} />
        <HeaderTitle>Community Science Report Layer</HeaderTitle>
        <CloseButton onPress={_ => onClose()}>
          <CloseIcon source={CancelIcon} />
        </CloseButton>
      </Header>
      {selectedLayer ? (
        <Current>
          <CurrentLabel>Current Layer:</CurrentLabel>
          <CurrentLayer>{selectedLayer.name}</CurrentLayer>
        </Current>
      ) : null}
      <Layers>
          <CurrentLabel>Select other layers:</CurrentLabel>
        {layers.map(layer => (
          <Layer key={layer.id} onPress={_ => selectLayer(layer.id)}>
            <LayerTitle>{layer.name}</LayerTitle>
            <LayerDescription>{layer.description}</LayerDescription>
          </Layer>
        ))}
      </Layers>
    </Container>
  );
}
