import {useEffect} from 'react';
import * as Location from 'expo-location';
import {useLocation} from '../hooks/useLocationContext';
import {useLoading} from '../hooks/useLoadingContext';
import {useNotification} from '../hooks/useNotificationContext';

export default function LocationController() {
  const {set: setLocation, clear: clearLocation} = useLocation();
  const {set: setLoading, clear: clearLoading} = useLoading();
  const {set: setNotification} = useNotification();

  const getLocation = async () => {
    setLoading();
    try {
      await Location.requestPermissionsAsync();
      let result = await Location.getCurrentPositionAsync({});
      setLocation({
        latitude: result.coords.latitude,
        longitude: result.coords.longitude,
      });
    } catch (error) {
      console.error('[LocationController] Location.getCurrentPositionAsync error:', error);
      setNotification({
        type: 'info',
        message: 'Failed to get location.',
      });
      clearLocation();
    } finally {
      clearLoading();
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  return null;
}
